import { useEffect, useState } from "react";
import classes from "../styles/ProjectPreview.module.css";
import { useNavigate } from "react-router-dom";
import details from "../projectData.json";

const ProjectPreview = ({ img, height, width, name, onLoad, video, link }) => {
  const [hovering, setHovering] = useState(false);
  const [previewName, setPreviewName] = useState();
  // const getProjName = (url) => {
  //   let pathParts = url.split("/");
  //   let projectName = pathParts[pathParts.length - 1];
  //   console.log(projectName);
  //   return projectName;
  // };
  // useEffect(() => {
  //   setPreviewName(details[getProjName(name)]?.name);
  // }, []);
  const navigate = useNavigate();
  return (
    <>
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={() => navigate(`/projects/${link}`)}
        className={classes.previewContainer}
      >
        {hovering && video && (
          <video autoPlay muted style={{ height: height, width: width }}>
            <source src={video} type="video/mp4"></source>
          </video>
        )}
        {
          <img
            onLoad={onLoad}
            style={{ height: height, width: width }}
            src={img}
          ></img>
        }
        <div
          className={`${classes.details} ${hovering ? classes.invisible : ``}`}
        >
          <p>{name || "Project Name"}</p>
          <span>Click to continue</span>
        </div>
      </div>
    </>
  );
};

export default ProjectPreview;

import { useEffect } from "react";
import classes from "../../styles/SubhankarConsulting.module.css";
import img1 from "./assets/01_LOGO.jpg";
import img2 from "./assets/02_LOGOMARK.png";
import img3 from "./assets/03_final logo.png";
import img4 from "./assets/04_typography.png";
import img5 from "./assets/cards.png";
import img6 from "./assets/poster.jpg";
import arrowImg from "../../assets/arrow.png";
import { Link } from "react-router-dom";
import ProjectDetails from "../../components/ProjectDetails";

const SubhankarConsulting = ({ setImgLoading }) => {
  useEffect(() => {
    setImgLoading(false);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ProjectDetails
        color="white"
        title="Subhankar Consulting"
        description="Integrating tech to assist growth and transformation."
        story="For over 2 decades, Shubhankar Consulting has assisted clients in growing and transforming their organizations by designing diverse and personalized business policies and procedures. They have then demonstrated the practicality of developed policies and strategies by integrating them with technology, strategy, and operational excellence."
        challenges="Finding the perfect balance between a branding that maintains the trust and familiarity of an older, performance-driven audience while simultaneously modernizing the company's image to appeal to a younger, more creative demographic."
        solution="We started the redesign by retaining elements of the familiar old look for the returning audience, such as the core values and performance focus. Consequently, we infused a fresh and creative perspective to allow the company to bridge the generation gap, welcoming a new, dynamic audience without alienating its longstanding supporters."
      />
      <div className={classes.mainContainer}>
        <img src={img1}></img>
        <img src={img2}></img>
        <img src={img3}></img>
        <img src={img4}></img>
        <img src={img5}></img>
        <img src={img6}></img>
        <div>
          <Link className={classes.nextProj} to="/projects/NutshellToday">
            Next Project
          </Link>
        </div>
      </div>
    </>
  );
};

export default SubhankarConsulting;

import classes from "../styles/Playground.module.css";
import heroImg from "../assets/heroPlayground.png";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

const Playground = ({ setImgLoading }) => {
  const [nImgLoaded, setnImgLoaded] = useState(0);
  const handleImgLoad = () => {
    setnImgLoaded((prev) => {
      return prev + 1;
    });
  };
  useEffect(() => {
    if (nImgLoaded == 1) {
      setImgLoading(false);
    }
  }, [nImgLoaded]);
  return (
    <>
      <Helmet>
        <title>Playground</title>
        <meta name="description" content="Coming soon!"></meta>
      </Helmet>
      <div className={classes.playgroundContainer}>
        <div className={classes.hero}>
          <img onLoad={handleImgLoad} src={heroImg}></img>
          <div className={classes.textContainer}>
            <p>Coming Soon</p>
            {/* <span>
              We are a series of creatives on a journey to explore what's
              possible. We tell stories, craft visually appealing imagery, and
              break down even the most complex processes into simple, digestible
              steps.
            </span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Playground;

import { useEffect } from "react";
import classes from "../../styles/StationDhaka.module.css";
import img1 from "./assets/02_Logo.png";
import img2 from "./assets/03_Logo technicalities.png";
import img3 from "./assets/04_Pattern GIF.gif";
import img4 from "./assets/05_Typography.png";
import img5 from "./assets/06_Color Palette.png";
import img6 from "./assets/07_Logo GIF.gif";
import img7 from "./assets/08_Billboard.jpg";
import img8 from "./assets/09.Cards.jpg";
import img9 from "./assets/10_Office.jpg";
import img10 from "./assets/11_Website.png";
import img11 from "./assets/12_Vertical Banner.png";

import alt1 from "../StationDhaka/assets/373472346_2046997702310287_6666398300394220675_n.png";
import alt2 from "../StationDhaka/assets/376428643_801254371750345_889089227302376628_n.png";
import { Link } from "react-router-dom";
import ProjectDetails from "../../components/ProjectDetails";

const StationDhaka = ({ setImgLoading }) => {
  useEffect(() => {
    setImgLoading(false);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ProjectDetails
        color="white"
        title="Station Dhaka"
        description="Connecting local startups globally."
        story="Local startups often struggle to connect with audiences internationally. Whether that is by bridging gaps in communication or finding synergies, Station Dhaka empowers the Bangladeshi tech and startup ecosystem by connecting them to the world."
        challenges="We were tasked to envision a branding structure that exhumed boldness, adequacy, and necessity - much like the startups that Station Dhaka connects globally from Bangladesh."
        solution="We focused on the global market rather than the Bangladeshi demographic alone to come up with a brand identity. The result? A brand identity that encourages more global partnership and awareness in the project, by making the website and social profiles concise with information and approachable in nature, while also ensuring all the elements fit in together cohesively and harmoniously."
      />

      <div className={classes.mainContainer}>
        <div className={classes.padding}></div>
        <img src={img6}></img>
        <img className="onlyPC" src={img1}></img>
        <img className="onlyMobile" src={alt1}></img>
        <img src={img2}></img>
        <img src={img3}></img>
        <img src={img4}></img>
        <img className="onlyPC" src={img5}></img>
        <img className="onlyMobile" src={alt2}></img>
        <img src={img7}></img>
        <img src={img8}></img>
        <img src={img9}></img>
        <img src={img10}></img>
        <img src={img11}></img>
        <div>
          <Link className={classes.nextProj} to="/projects/DeepDive">
            Next Project
          </Link>
        </div>
      </div>
    </>
  );
};

export default StationDhaka;

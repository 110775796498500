import classes from "../styles/About.module.css";
import heroImg from "../assets/noise.png";
import Dropdown from "../components/Dropdown";
import { useEffect, useRef, useState } from "react";

import emailjs from "@emailjs/browser";
import axios from "axios";
import { Helmet } from "react-helmet-async";

const About = ({ setImgLoading }) => {
  const [data, setData] = useState({});
  const [sent, setSent] = useState(false);
  const [err, setErr] = useState(false);
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const sendMail = async () => {
    if (!data.name || !data.email) {
      setErr(true);
      return;
    }
    setErr(false);
    console.log(data);
    setSent(true);
    handleClick();
    try {
      await emailjs.send(
        "service_m9e5lg4",
        "template_nj2keop",
        {
          from_name: data.name,
          from_company: data.company,
          from_category: data.category,
          from_duration: data.duration,
          from_amount: data.budget,
          from_email: data.email,
          from_description: data.details,
        },
        `xWn6gSzgHDeESMG_E`
      );
      const botApi =
        "https://api.telegram.org/bot6340936532:AAGRKYRz9S3hVFCPYTCrqEHPyLQaeZdaemI/sendMessage";
      const response = await axios.post(botApi, {
        chat_id: `-4036580457`,
        text: `New Client Request: ${data.name}\n\nName: ${data.name}\nCompany: ${data.company}\nCategory: ${data.category}\nDuration: ${data.duration}\nAmount: ${data.budget}\nEmail: ${data.email}\nDescription: ${data.details}`,
        parse_mode: "HTML",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const [nImgLoaded, setnImgLoaded] = useState(0);
  const handleImgLoad = () => {
    setnImgLoaded((prev) => {
      return prev + 1;
    });
  };
  useEffect(() => {
    if (nImgLoaded == 1) {
      setImgLoading(false);
    }
  }, [nImgLoaded]);
  useEffect(() => {
    console.log("yea");
    setImgLoading(true);
  }, []);
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="We are a Design-Driven Visual and Motion Studio on a mission to craft narratives to elevate brands worldwide through the fusion of technology and design"
        ></meta>
      </Helmet>
      <div className={classes.hero}>
        <img onLoad={handleImgLoad} src={heroImg}></img>
        <div className={classes.textContainer}>
          <p>In a nutshell...</p>
          <span>
            We are a Design-Driven Visual and Motion Studio on a mission to
            craft narratives to elevate brands worldwide through the fusion of
            technology and design
          </span>
        </div>
      </div>

      {!sent && (
        <div className={classes.form}>
          <p>Let's get in touch</p>
          <div className={classes.innerBox}>
            <div>Greetings Robyn!</div>
            <br></br>
            <div>
              <div>My name is</div>
              <input
                onChange={(e) => {
                  setData((prev) => ({ ...prev, name: e.target.value }));
                }}
                className={classes.input}
                placeholder="Your Name*"
              ></input>
              <div>and I work with</div>
              <input
                onChange={(e) => {
                  setData((prev) => ({ ...prev, company: e.target.value }));
                }}
                className={classes.input}
                placeholder="Your Company"
              ></input>
            </div>
            <div>
              <div>I'm looking for some help in</div>
              <Dropdown
                options={[
                  "Branding",
                  "Animation",
                  "Product Design",
                  "Video Editing",
                ]}
                placeholder="Select category"
                setData={(category) => {
                  setData((prev) => ({ ...prev, category: category }));
                }}
              />
              <div>My estimated project timeline is</div>
              <Dropdown
                options={["2 weeks", "4 weeks", "8 weeks", "12 weeks"]}
                placeholder="Select duration"
                setData={(duration) => {
                  setData((prev) => ({ ...prev, duration: duration }));
                }}
              />
            </div>
            <div>
              <div>and my budget is about</div>
              <input
                onChange={(e) => {
                  setData((prev) => ({ ...prev, budget: e.target.value }));
                }}
                className={classes.input}
                placeholder="Amount in $"
              ></input>
              <div>You can reach me at</div>
              <input
                onChange={(e) => {
                  setData((prev) => ({ ...prev, email: e.target.value }));
                }}
                className={classes.input}
                placeholder="Your Email*"
              ></input>
            </div>
            <div>
              <div>Here’s some more additional project details</div>
              <input
                onChange={(e) => {
                  setData((prev) => ({ ...prev, details: e.target.value }));
                }}
                className={classes.input}
                placeholder="Description"
              ></input>
            </div>
            <button
              onClick={() => {
                sendMail();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      {err && (
        <div className={classes.error}>Please enter the required fields</div>
      )}
      {sent && (
        <div ref={ref} className={classes.success}>
          <p>Thanks for submitting!</p>
          <span>You will hear from us shortly</span>
        </div>
      )}
      <div className={`flex-row ${classes.list}`}>
        <div className="flex-row">
          <div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6.65121 17.2693C10.3552 20.9858 14.8627 23.8485 18.5416 23.8485C20.1864 23.8485 21.6303 23.2709 22.7854 21.9902C23.4634 21.2369 23.8778 20.3705 23.8778 19.5042C23.8778 18.8387 23.6141 18.1858 22.9738 17.7338L19.1191 14.9967C18.5039 14.5698 18.0017 14.3689 17.5496 14.3689C16.9595 14.3689 16.4322 14.6953 15.8546 15.2729L14.9506 16.1769C14.7999 16.315 14.6241 16.3778 14.4609 16.3778C14.26 16.3778 14.0717 16.3025 13.9461 16.2271C13.1551 15.8002 11.7991 14.6451 10.5435 13.3895C9.28793 12.1339 8.12024 10.7905 7.7059 9.98689C7.63056 9.84878 7.56778 9.673 7.56778 9.47211C7.56778 9.32144 7.61801 9.14566 7.75612 8.99499L8.66014 8.07841C9.22515 7.48829 9.56416 6.9735 9.56416 6.38338C9.56416 5.91881 9.36326 5.41658 8.93637 4.8139L6.21176 0.996939C5.74719 0.344038 5.09429 0.0678101 4.37861 0.0678101C3.52482 0.0678101 2.67102 0.444484 1.93023 1.16016C0.674648 2.34041 0.122192 3.80944 0.122192 5.42914C0.122192 9.10799 2.94725 13.5653 6.65121 17.2693Z" />
            </svg>
          </div>

          <div className={`flex-column ${classes.left}`}>
            <p>Phone</p>
            <p className={classes.subtitle}>+8801683892408 </p>
          </div>
        </div>
        <div className="flex-row">
          <div>
            <svg
              width="30"
              height="23"
              viewBox="0 0 30 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.0126 12.8246C15.5525 12.8246 16.0296 12.5734 16.5946 12.0084L27.5433 1.14765C27.0536 0.7082 26.1873 0.494751 24.9694 0.494751H4.71685C3.63705 0.494751 2.87115 0.695644 2.43169 1.09743L13.4306 12.0084C13.983 12.5734 14.4727 12.8246 15.0126 12.8246ZM1.22634 20.4208L10.254 11.4811L1.23889 2.57901C1.01289 2.9808 0.887329 3.64626 0.887329 4.58794V18.4621C0.887329 19.3787 1.00033 20.0316 1.22634 20.4208ZM28.7738 20.4083C28.9872 20.019 29.1002 19.3661 29.1002 18.4621V4.58794C29.1002 3.67137 28.9747 3.00591 28.7487 2.61668L19.7713 11.4811L28.7738 20.4083ZM5.01819 22.5553H25.2707C26.3756 22.5553 27.1541 22.3419 27.6061 21.9275L18.4152 12.7994L17.6368 13.5653C16.7704 14.4066 15.9417 14.8084 15.0126 14.8084C14.0835 14.8084 13.2422 14.4066 12.3759 13.5653L11.5974 12.7994L2.41914 21.9024C2.92137 22.3293 3.80028 22.5553 5.01819 22.5553Z" />
            </svg>
          </div>

          <div className={`flex-column ${classes.left}`}>
            <p>Email</p>
            <p className={classes.subtitle}>hello@robyn.agency</p>
          </div>
        </div>
        <div className="flex-row">
          <div>
            <svg
              width="26"
              height="26"
              viewBox="0 0 29 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.54941 14.1008L11.8909 14.1384C12.0793 14.1384 12.142 14.2138 12.142 14.4021L12.1672 23.6808C12.1672 25.8404 14.8667 26.3301 15.8209 24.2458L25.3256 3.67944C26.3427 1.48217 24.6351 0.025696 22.5383 0.992493L1.8714 10.5223C-0.0496367 11.4013 0.352149 14.0882 2.54941 14.1008Z" />
            </svg>
          </div>

          <div className={`flex-column ${classes.left}`}>
            <p>Address</p>
            <p className={classes.subtitle}>
              270/B, Tejgaon, Dhaka-1208, Bangladesh
            </p>
          </div>
        </div>

        {/* <div className="flex-column">
          <svg
            width="28"
            height="25"
            viewBox="0 0 28 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.23079 17.0217C2.03436 17.7751 3.5285 17.9634 4.70874 16.8962C5.13564 16.5069 5.96432 15.628 7.24502 14.3348C13.4978 8.01922 17.3273 4.03903 17.9928 4.65427C18.5327 5.13139 16.3982 8.14478 14.2637 11.108C10.3589 16.5195 7.06924 20.4871 9.55528 22.9732C11.4889 24.9193 14.4144 23.1741 19.1479 19.0181C21.6842 16.7957 23.4169 15.1635 23.8438 15.5778C24.12 15.854 23.442 16.9464 22.6008 18.3903C21.3954 20.4871 19.7757 22.8476 21.3577 24.4297C22.4627 25.5346 24.5595 25.0449 26.9953 22.6467C27.4975 22.1445 27.6105 21.5418 27.1836 21.1149C26.7818 20.7257 26.2168 20.7885 25.7899 21.1777C24.2456 22.6593 23.0779 23.3247 22.8895 23.1239C22.6635 22.8979 23.3416 21.705 24.4841 19.671C26.0787 16.8208 27.0581 14.8998 25.5765 13.4559C23.5927 11.5223 21.2698 13.5563 17.2645 17.0845C13.9121 20.0351 12.4808 20.8513 12.192 20.5248C11.7525 20.0728 13.0458 18.3024 16.7121 13.1797C20.7174 7.57977 23.1156 3.91348 20.843 1.70365C17.8923 -1.18418 13.8996 0.925194 4.14373 10.5932C2.69982 12.012 1.7958 12.8783 1.33123 13.4308C0.163543 14.7742 0.427215 16.2433 1.23079 17.0217Z" />
          </svg>

          <p>We are visionary</p>
          <p className={classes.subtitle}>
            Lorem ipsum dolor sit amet adipiscing tempor.
          </p>
        </div>
        <div className="flex-column">
          <svg
            width="28"
            height="24"
            viewBox="0 0 28 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.641113 21.6593C0.239327 22.5759 0.616002 23.2037 1.59535 23.3167L6.79346 23.8943C8.12437 24.0575 8.90283 23.1786 8.19971 21.9858L7.38358 20.5795L14.0005 17.2523L20.6048 20.5795L19.7887 21.9858C19.0981 23.1786 19.8766 24.0575 21.195 23.8943L26.4056 23.3167C27.3724 23.2037 27.7491 22.5759 27.3599 21.6593L25.2379 16.863C24.7231 15.6577 23.568 15.4317 22.9026 16.5993L21.8102 18.4953L15.2058 15.1805V7.14483H17.0767C18.4704 7.14483 18.847 6.05247 18.0435 4.97267L14.9422 0.753924C14.3395 -0.0496477 13.6238 -0.0496477 13.0211 0.753924L9.93241 4.96012C9.14139 6.02736 9.53062 7.14483 10.8866 7.14483H12.7826V15.1805L6.19078 18.4953L5.08587 16.5993C4.42041 15.4317 3.27783 15.6577 2.75049 16.863L0.641113 21.6593Z" />
          </svg>

          <p>We are visionary</p>
          <p className={classes.subtitle}>
            Lorem ipsum dolor sit amet adipiscing tempor.
          </p>
        </div> */}
      </div>
    </>
  );
};

export default About;

import { useEffect } from "react";
import classes from "../../styles/GolpoGhuri.module.css";
import img1 from "./assets/img1.png";
import img2 from "./assets/img2.png";
import img3 from "./assets/img3.png";
import img4 from "./assets/img4.png";
import img5 from "./assets/img5.png";
import img6 from "./assets/img6.png";
import img7 from "./assets/img7.png";
import img8 from "./assets/img8.png";
import img9 from "./assets/img9.png";

import { Link } from "react-router-dom";
import ProjectDetails from "../../components/ProjectDetails";

const GolpoGhuri = ({ setImgLoading }) => {
  useEffect(() => {
    setImgLoading(false);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ProjectDetails
        color="white"
        title="Golpo Ghuri"
        description="Financial literacy, simplified."
        story="The world of business and finance is cluttered with complex issues that intimidate younger audiences away. Golpo Ghuri works to fix that by introducing a new wave of podcasts to usher young people into the business world."
        challenges="Designing an identity that appeals to the youth while also appearing bold to an educated audience."
        solution="Robyn tackled the challenge by designing an identity with a laid-back approach and by incorporating elements that would interest the younger audience better."
      />

      <div className={classes.mainContainer}>
        <div className={classes.padding}></div>
        <img src={img5}></img>
        <img src={img1}></img>
        <img src={img2}></img>
        <img src={img3}></img>
        <img src={img4}></img>
        <img src={img6}></img>
        <img src={img7}></img>
        <img src={img8}></img>
        <img src={img9}></img>
        <div>
          <Link className={classes.nextProj} to="/projects/SubhankarConsulting">
            Next Project
          </Link>
        </div>
      </div>
    </>
  );
};

export default GolpoGhuri;

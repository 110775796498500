import { useEffect } from "react";
import classes from "../../styles/TurtleVentre.module.css";
import video from "./assets/video.mp4";
import img1 from "./assets/tvs.png";
import img2 from "./assets/02_color & type.jpg";
import img3 from "./assets/Hoodie.jpg";
import img4 from "./assets/Letter Mockup.jpg";
import img5 from "./assets/Turtle Venture_Studio.jpg";
import img6 from "./assets/TV_Business Card.jpg";
import img7 from "./assets/Wall Poster_.jpg";
import { Link } from "react-router-dom";
import ProjectDetails from "../../components/ProjectDetails";

const TurtleVenture = ({ setImgLoading }) => {
  useEffect(() => {
    setImgLoading(false);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ProjectDetails
        color="white"
        title="Turtle Venture"
        description="Helping ideas turn into thriving businesses."
        story="Turtle Venture Studio helps Bangladeshi early-stage startups and founders by providing them the right resources, funding, and enough venture-building time to build the right groundbreaking products. They do so by onboarding promising founders, intensive six months of venture building process, demo day in Singapore, and fundraising."
        challenges="Introducing the nascent VC studio to a broader audience of professionals while incorporating the company's core values into the logo's design to establish it as an emblem of unwavering assurance and audacity."
        solution="We started off by looking at the big picture. What was Turtle Venture trying to do differently from other VC firms in the region? Therefore, we focused on the core company values - and doubled down on the abstraction part. As a result, the design grew in meaning and significance as one delved deeper, which fostered a strong sense of connection and trust among its audience."
      />
      <div className={classes.mainContainer}>
        <div className={classes.padding}></div>
        <img src={img1}></img>
        <img src={img2}></img>
        <img src={img3}></img>
        <img src={img4}></img>
        <img src={img5}></img>
        <video preload="auto" playsInline autoPlay muted>
          <source src={video} type="video/mp4"></source>
        </video>
        <img src={img6}></img>
        <img src={img7}></img>
        <div>
          <Link className={classes.nextProj} to="/projects/StationDhaka">
            Next Project
          </Link>
        </div>
      </div>
    </>
  );
};

export default TurtleVenture;

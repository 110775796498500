import { useEffect, useState } from "react";
import classes from "../styles/ProjectDetails.module.css";

const ProjectDetails = ({
  color,
  title,
  description,
  story,
  challenges,
  solution,
}) => {
  const [toggleDetails, setToggleDetails] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 800);
    });
  }, []);
  return (
    <>
      <div className={classes.detailsLink}>
        <div className={scroll && classes.backPadding}></div>
        <p onClick={() => setToggleDetails(true)} style={{ color: color }}>
          + Project Details
        </p>
      </div>
      <div
        className={`${classes.mainDetails} ${toggleDetails && classes.active}`}
      >
        <div className={classes.svgContainer}>
          <svg
            onClick={() => setToggleDetails(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffffff"
            height="30px"
            width="30px"
            version="1.1"
            id="Layer_1"
            viewBox="0 0 476.213 476.213"
          >
            <polygon points="476.213,223.107 57.427,223.107 151.82,128.713 130.607,107.5 0,238.106 130.607,368.714 151.82,347.5   57.427,253.107 476.213,253.107 " />
          </svg>
        </div>
        <br></br>
        <div>
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
        <br></br>
        <br></br>
        <div>
          <h1>Story</h1>
          <p>{story}</p>
        </div>
        <br></br>
        <div>
          <h1>Challenges</h1>
          <p>{challenges}</p>
        </div>
        <br></br>
        <div>
          <h1>Solution</h1>
          <p>{solution}</p>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    </>
  );
};

export default ProjectDetails;

import classes from "../styles/Footer.module.css";

const Footer = ({ color }) => {
  return (
    <>
      <div
        className={classes.footerContainer}
        style={{ background: `${color == "black" ? `white` : `black`}` }}
      >
        <div className={classes.logo} style={{ color: color }}>
          <svg
            width="30px"
            height="30px"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.5514 20.6057C18.3026 20.6057 14.8583 24.05 14.8583 28.2988H30.2445C30.2445 24.05 26.8001 20.6057 22.5514 20.6057Z"
              fill={color}
            />
            <path
              d="M1.72437 15.982C1.72437 22.8567 7.29752 28.4299 14.1722 28.4299V3.53436C7.29752 3.53436 1.72437 9.10738 1.72437 15.982Z"
              fill={color}
            />
          </svg>
          <p>Robyn</p>
        </div>
        <div className={classes.tyger}>
          © 2023 Tyger Media <span>/ Dhaka, Bangladesh</span>
        </div>
        <div className={classes.links}>
          <p
            onClick={() =>
              window.open(
                `https://www.instagram.com/robyn.agency/`,
                "_blank",
                "noreferrer"
              )
            }
          >
            Instagram
          </p>
          <p
            onClick={() =>
              window.open(
                `https://www.linkedin.com/company/robynbd`,
                "_blank",
                "noreferrer"
              )
            }
          >
            Linkedin
          </p>
          <p
            onClick={() =>
              window.open(
                `https://twitter.com/robyn_agency`,
                "_blank",
                "noreferrer"
              )
            }
          >
            Twitter
          </p>
          <p
            onClick={() =>
              window.open(
                `https://www.facebook.com/robyn.agency`,
                "_blank",
                "noreferrer"
              )
            }
          >
            Facebook
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;

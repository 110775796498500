import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Loading from "./components/Loading";
import { useEffect, useState } from "react";
import Home from "./pages/Home";
import "./App.css";
import About from "./pages/About";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Playground from "./pages/Playground";
import SubhankarConsulting from "./projects/SubhankarConsulting/SubhankarConsulting";
import TurtleVenture from "./projects/TurtleVenture/TurtleVenture";
import StationDhaka from "./projects/StationDhaka/StationDhaka";
import Deepdive from "./projects/Deepdive/Deepdive";
import NutshellToday from "./projects/NutshellToday/NutshellToday";
import GolpoGhuri from "./projects/GolpoGhuri/GolpoGhuri";

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(true);

  // Define the animation variants
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageVariants2 = {
    initial: {
      x: "100%",
    },
    in: {
      x: "0",
    },
    out: {
      x: "100%",
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5,
  };

  return (
    <div className="App">
      <Loading
        setLoading={setLoading}
        loading={loading}
        imgLoading={imgLoading}
      />
      {
        <>
          <Navbar
            color={
              location.pathname == "/" || location.pathname.includes("projects")
                ? `white`
                : `black`
            }
          />

          <AnimatePresence mode="wait">
            <Routes>
              <Route
                path="/"
                exact
                element={
                  <motion.div
                    key="home"
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Home setImgLoading={setImgLoading} />
                  </motion.div>
                }
              ></Route>
              <Route
                path="/playground"
                exact
                element={
                  <motion.div
                    key="playground"
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Playground setImgLoading={setImgLoading} />
                  </motion.div>
                }
              ></Route>
              <Route
                path="/about"
                exact
                element={
                  <motion.div
                    key="about"
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <About setImgLoading={setImgLoading} />
                  </motion.div>
                }
              ></Route>
              <Route
                path="/projects/SubhankarConsulting"
                exact
                element={
                  <motion.div
                    key="project"
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <SubhankarConsulting setImgLoading={setImgLoading} />
                  </motion.div>
                }
              ></Route>
              <Route
                path="/projects/TurtleVenture"
                exact
                element={
                  <motion.div
                    key="project"
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <TurtleVenture setImgLoading={setImgLoading} />
                  </motion.div>
                }
              ></Route>
              <Route
                path="/projects/StationDhaka"
                exact
                element={
                  <motion.div
                    key="project"
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <StationDhaka setImgLoading={setImgLoading} />
                  </motion.div>
                }
              ></Route>
              <Route
                path="/projects/Deepdive"
                exact
                element={
                  <motion.div
                    key="project"
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Deepdive setImgLoading={setImgLoading} />
                  </motion.div>
                }
              ></Route>
              <Route
                path="/projects/NutshellToday"
                exact
                element={
                  <motion.div
                    key="project"
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <NutshellToday setImgLoading={setImgLoading} />
                  </motion.div>
                }
              ></Route>
              <Route
                path="/projects/GolpoGhuri"
                exact
                element={
                  <motion.div
                    key="project"
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <GolpoGhuri setImgLoading={setImgLoading} />
                  </motion.div>
                }
              ></Route>
            </Routes>
          </AnimatePresence>

          <Footer color={`black`} />
        </>
      }
    </div>
  );
}

export default App;

import classes from "../../styles/NutshellToday.module.css";

import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.png";
import img3 from "./assets/img3.jpg";
import img4 from "./assets/img4.png";
import img5 from "./assets/img5.png";
import video from "./assets/Logo.mp4";

import { useEffect } from "react";
import { Link } from "react-router-dom";
import ProjectDetails from "../../components/ProjectDetails";

const NutshellToday = ({ setImgLoading }) => {
  useEffect(() => {
    setImgLoading(false);
    window.scrollTo(0, 0);
  }, []);
  // const video = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Titles.mp4?alt=media&token=71c72d43-6c88-4438-b952-d9fcd4a65faf`;
  // const video2 = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Render.mp4?alt=media&token=7344ae92-4ec2-43ca-ab4c-4f84e059e4ef`;
  // const video3 = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Soft_OUT.mp4?alt=media&token=4b3561ba-7a63-4935-ad62-08505daf315e`;
  // const video4 = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Mobile.mp4?alt=media&token=2c0f6d0e-7ffd-4ece-be4d-c9c047c34751`;
  // const video5 = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Turtle%20Venture_Website%20Mockup_3.mp4?alt=media&token=c1376d17-e3d6-4977-9b66-79baae9d36e8`;

  return (
    <>
      <ProjectDetails
        color="white"
        title="Nutshell Today"
        description="Transforming content for the youth, one byte at a time."
        story="As traditional forms of media consumption are being outdated every moment as you read this, Nutshell Today identified the issue lies with attention retention, besides the digitalisation of information. Hence, Nutshell Today introduced a byte-sized news media platform for the youth who struggle with paying attention for long."
        challenges="Crafting a branding and design identity that is on-the-face and grappling, and can grab the viewer’s attention in less than 3 seconds - by bringing what matters minus the noise."
        solution="We started by eliminating noise from not only the pool of content that Nutshell Today would create and produce, but also from every facet of the platform. The result? Nutshell Today grew to become the biggest byte-sized content platform in Bangladesh across both Facebook and Instagram - amassing over 10 million impressions every month."
      />
      <div className={classes.mainContainer}>
        <video preload="auto" playsInline autoPlay muted loop>
          <source src={video} type="video/mp4"></source>
        </video>
        <img src={img1}></img>
        <div className={`flex-row ${classes.grid}`}>
          <div className="flex-column">
            <img src={img2}></img>
            <img src={img3}></img>
          </div>

          <img src={img4}></img>
        </div>

        <img src={img5}></img>
        <div>
          <Link className={classes.nextProj} to="/projects/TurtleVenture">
            Next Project
          </Link>
        </div>
      </div>
    </>
  );
};

export default NutshellToday;

import classes from "../../styles/Deepdive.module.css";

import img2 from "./assets/02.jpg";
import img3 from "./assets/376387119_339729268489166_3665817926945029816_n (1).jpg";
import img4 from "./assets/376428900_328829609630364_6128166574827022812_n.jpg";
import img7 from "./assets/Asset 1.png";
import img5 from "./assets/Asset 2.png";
import img6 from "./assets/Asset 3.png";
import video from "./assets/Titles.mp4";
import video2 from "./assets/Render.mp4";
import video3 from "./assets/Soft_OUT.mp4";
import video4 from "./assets/Mobile.mp4";
import video5 from "./assets/Turtle Venture_Website Mockup_3.mp4";

import { useEffect } from "react";
import { Link } from "react-router-dom";
import ProjectDetails from "../../components/ProjectDetails";

const Deepdive = ({ setImgLoading }) => {
  useEffect(() => {
    setImgLoading(false);
    window.scrollTo(0, 0);
  }, []);
  // const video = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Titles.mp4?alt=media&token=71c72d43-6c88-4438-b952-d9fcd4a65faf`;
  // const video2 = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Render.mp4?alt=media&token=7344ae92-4ec2-43ca-ab4c-4f84e059e4ef`;
  // const video3 = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Soft_OUT.mp4?alt=media&token=4b3561ba-7a63-4935-ad62-08505daf315e`;
  // const video4 = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Mobile.mp4?alt=media&token=2c0f6d0e-7ffd-4ece-be4d-c9c047c34751`;
  // const video5 = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Turtle%20Venture_Website%20Mockup_3.mp4?alt=media&token=c1376d17-e3d6-4977-9b66-79baae9d36e8`;

  return (
    <>
      <ProjectDetails
        color="white"
        title="Deep Dive"
        description="Diving deep into how the US credit system works."
        story="Extra's flagship product is a debit card that builds credit for US-based customers. Credit - a financial rating system in the US - works by assigning every person in the United States who wishes to apply for loans a score regarding how good or bad their credit history is. Better credit means better potential to borrow funds for purchases such as homes, cars, schooling, etc. Having bad or non-existent credit means less opportunity. For many, the problem with this system is you are required to incur debt in many cases to build good credit. Extra solves this by providing a product that allows customers to build credit safely and without incurring large amounts of debt. Customers can use their Extra debit card to pay for everyday things such as food or gas, and safely build credit doing it without incurring large amounts of debt or credit card fees."
        challenges="Extra requested Robyn’s skillset in 3D work for their podcast, Deep Dive. The goal of this podcast is to educate their customers on how credit works in the United State, and is meant to be both educational and interesting. The podcast lives within the Extra app, and customers who use their products can access these podcasts from the app."
        solution="We approached the task by visualizing the brand’s positive energy through design and motion that feels soft and relaxing, reflecting the brand’s ability to provide a stress-free lifestyle as a buffer against financial stress. The result? A complete branding that feels easy to approach, use, and learn from, much like Deep Dive’s philosophy about educating about credit."
      />
      <div className={classes.mainContainer}>
        <div className={classes.padding}></div>
        <video
          className={classes.slightlyScale}
          preload="auto"
          playsInline
          autoPlay
          muted
          loop
        >
          <source src={video} type="video/mp4"></source>
        </video>

        <video
          preload="auto"
          className="onlyPC"
          playsInline
          autoPlay
          muted
          loop
        >
          <source src={video2} type="video/mp4"></source>
        </video>
        <video
          preload="auto"
          className="onlyMobile"
          playsInline
          autoPlay
          muted
          loop
        >
          <source src={video4} type="video/mp4"></source>
        </video>
        <div className={`flex-row ${classes.grid}`}>
          <div className="flex-column">
            <img src={img3}></img>
            <img src={img4}></img>
          </div>
          <video preload="auto" playsInline autoPlay muted loop>
            <source src={video3} type="video/mp4"></source>
          </video>
        </div>
        <div className={`flex-row ${classes.grid2}`}>
          <img src={img6}></img>
          <div className={`flex-column`}>
            <img src={img7}></img>
            <img src={img5}></img>
          </div>
        </div>
        <video preload="auto" playsInline autoPlay muted loop>
          <source src={video5} type="video/mp4"></source>
        </video>
        <div>
          <Link className={classes.nextProj} to="/projects/GolpoGhuri">
            Next Project
          </Link>
        </div>
      </div>
    </>
  );
};

export default Deepdive;

import Navbar from "../components/Navbar";
import heroImg from "../assets/hero.png";
import classes from "../styles/Home.module.css";
import ProjectPreview from "../components/ProjectPreview";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import thumb1 from "../projects/SubhankarConsulting/assets/01_LOGO.jpg";
import thumb2 from "../projects/TurtleVenture/assets/Wall Poster_.jpg";
import thumb3 from "../projects/Deepdive/assets/02.jpg";
import thumb4 from "../projects/StationDhaka/assets/08_Billboard.jpg";
import thumb5 from "../projects/GolpoGhuri/assets/img5.png";
import thumb6 from "../projects/NutshellToday/assets/img3.jpg";

import heroVid from "../assets/heroVid.mp4";
import { Helmet } from "react-helmet-async";

// const heroVid = `https://firebasestorage.googleapis.com/v0/b/robyn-809c4.appspot.com/o/Website_Vid_3.mp4?alt=media&token=0e4f7300-f369-4276-8de9-f4756f298cbb`;

const Home = ({ setImgLoading }) => {
  const navigate = useNavigate();
  const [nImgLoaded, setnImgLoaded] = useState(0);
  const handleImgLoad = () => {
    setnImgLoaded((prev) => {
      return prev + 1;
    });
  };
  useEffect(() => {
    // if (nImgLoaded == 1) {
    //   setImgLoading(false);
    // }
    setImgLoading(false);
  }, [nImgLoaded]);

  useEffect(() => {
    // setImgLoading(true);
  }, []);
  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="We commit to crafting Purpose-driven Designs with visuals that convey meaning and leave a lasting impact on users and audiences."
        ></meta>
      </Helmet>
      <div className={classes.homeContainer}>
        <p>Purpose-driven Design</p>
        {/* <img  src={heroImg} /> */}
        <video
          autoPlay
          muted
          loop
          onCanPlay={handleImgLoad}
          playsInline
          preload="auto"
        >
          <source src={heroVid} type="video/mp4"></source>
        </video>
      </div>
      <div className={classes.projectsContainer}>
        <p>Featured</p>
        <br></br>
        <br></br>
        <div className={classes.previews}>
          <div className="flex-row">
            <ProjectPreview
              img={thumb2}
              // onLoad={handleImgLoad}
              height="23rem"
              width={`${23 * 1.6}rem`}
              name={`Turtle Venture`}
              link="TurtleVenture"
            />
            <ProjectPreview
              img={thumb4}
              // onLoad={handleImgLoad}
              height="23rem"
              width={`${23 * 1.6}rem`}
              name={`Station Dhaka`}
              link="StationDhaka"
            />
          </div>
          {/* <div className="flex-row">
            <ProjectPreview
              img={thumb1}
              // onLoad={handleImgLoad}
              height="23rem"
              width={`${23 * 1.6}rem`}
              name={`Subhankar Consulting`}
              link="SubhankarConsulting"
            />
            <ProjectPreview
              img={thumb4}
              // onLoad={handleImgLoad}
              height="23rem"
              width={`${23 * 1.6}rem`}
              name={`Station Dhaka`}
              link="StationDhaka"
            />
          </div> */}
          <div className="flex-row">
            <ProjectPreview
              img={thumb3}
              // onLoad={handleImgLoad}
              height="46.7rem"
              width={`${23 * 1.6}rem`}
              name={`Deep Dive`}
              link="DeepDive"
            />
            <div className="flex-column">
              <ProjectPreview
                img={thumb5}
                // onLoad={handleImgLoad}
                height="23rem"
                width={`${23 * 1.6}rem`}
                name={`Golpo Ghuri`}
                link="GolpoGhuri"
              />
              <ProjectPreview
                img={thumb1}
                // onLoad={handleImgLoad}
                height="23rem"
                width={`${23 * 1.6}rem`}
                name={`Subhankar Consulting`}
                link="SubhankarConsulting"
              />
            </div>
          </div>
          {/* <div className="flex-row">
            <ProjectPreview
              img={ProjectPreview1}
              onLoad={handleImgLoad}
              height="23rem"
              width={`${23 * 1.6}rem`}
              name={`Project1`}
              video={VideoPreview1}
            />
            <ProjectPreview
              img={ProjectPreview2}
              onLoad={handleImgLoad}
              height="23rem"
              width={`${23 * 1.6}rem`}
              name={`Project2`}
            />
          </div> */}

          <ProjectPreview
            img={thumb6}
            onLoad={handleImgLoad}
            height="23rem"
            width={`${23 * 1.6 * 2 + 0.7}rem`}
            name={`Nutshell Today`}
            link="NutshellToday"
          />
          {/* <div className="flex-row">
            <ProjectPreview
              img={ProjectPreview7}
              onLoad={handleImgLoad}
              height="23rem"
              width={`${(22.9 * 1.6) / 2}rem`}
              name={`Project7`}
            />
            <ProjectPreview
              img={ProjectPreview8}
              onLoad={handleImgLoad}
              height="23rem"
              width={`${(22.9 * 1.6) / 2}rem`}
              name={`Project8`}
              video={VideoPreview8}
            />
            <ProjectPreview
              img={ProjectPreview9}
              onLoad={handleImgLoad}
              height="23rem"
              width={`${22.7 * 1.6}rem`}
              name={`Project9`}
            />
          </div> */}
        </div>
      </div>
      <div className={classes.bottomHome}>
        <p>Ready to discuss?</p>
        <button onClick={() => navigate("/about")}>Lets get in touch!</button>
      </div>
    </>
  );
};

export default Home;

import React, { useState, useEffect, useRef } from "react";
import "../styles/Dropdown.css"; // Import your CSS file

const Dropdown = ({ options, placeholder, setData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const node = useRef(); // this line

  useEffect(() => {
    // this hook
    // close dropdown when clicking outside of it
    const handleClickOutside = (e) => {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setIsOpen(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // empty array means this effect runs on mount and unmount

  useEffect(() => {
    setData(selectedOption);
  }, [selectedOption]);

  return (
    <div className="custom-select" ref={node} onClick={toggling}>
      {" "}
      {/* apply the ref here */}
      <div className={`select-selected select-arrow-active`}>
        {selectedOption || placeholder}
      </div>
      {isOpen && (
        <div className="select-items">
          {options.map((option) => (
            <div
              className={`item ${
                selectedOption === option ? "same-as-selected" : ""
              }`}
              onClick={onOptionClicked(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

import { useEffect, useState } from "react";
import "../styles/Loading.scss";

const Loading = ({ setLoading, loading, imgLoading }) => {
  const [percentage, setPercentage] = useState(0);
  const [animationLoaded, setAnimationLoaded] = useState(false);

  useEffect(() => {
    let start = null;
    const duration = 4000; // Total time for one complete cycle (0% to 50%)

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const elapsed = timestamp - start;

      const t = Math.min(1, elapsed / duration);
      const easedT = easeInOutCubic(t);
      setPercentage(easedT * 50);

      // Request another frame only if t is less than 1
      if (t < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);

    // cleanup function
    return () => cancelAnimationFrame(step);
  }, []);

  useEffect(() => {
    if (percentage > 49) {
      setAnimationLoaded(true);
    }
  }, [percentage]);

  function easeInOutCubic(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  }

  useEffect(() => {
    if (animationLoaded && !imgLoading) {
      setLoading(false);
    }
    // else {
    //   setLoading(true);
    // }
  }, [animationLoaded, imgLoading]);

  const fillerHeight = (percentage * 60) / 100;
  return (
    <>
      <div className={`loadingContainer ${!loading ? `loaded` : ``}`}>
        <div className={`loading `}>
          <svg
            id="loading"
            className="loading__logo"
            width="30"
            height="30"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0" y="0" width="30" height="30" fill="#000000" />
            <rect
              x="0"
              y={30 - fillerHeight}
              width="30"
              height={fillerHeight}
              fill="#ffffff"
              className="loading__filler"
            />
          </svg>
        </div>
        <svg
          className="svg-defs"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 90 150"
          // xml:space="preserve"
        >
          <clipPath id="pictogram">
            <path
              d="M22.5514 20.6057C18.3026 20.6057 14.8583 24.05 14.8583 28.2988H30.2445C30.2445 24.05 26.8001 20.6057 22.5514 20.6057Z"
              fill="white"
            />
            <path
              d="M1.72437 15.982C1.72437 22.8567 7.29752 28.4299 14.1722 28.4299V3.53436C7.29752 3.53436 1.72437 9.10738 1.72437 15.982Z"
              fill="white"
            />
          </clipPath>
        </svg>
      </div>
    </>
  );
};

export default Loading;

import { useLocation, useNavigate } from "react-router-dom";
import classes from "../styles/Navbar.module.css";
import { useEffect, useState } from "react";

const Navbar = ({ color }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 800);
    });
  }, []);

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  console.log(location.pathname);
  return (
    <>
      <div
        className={`${classes.navHolder} ${scroll ? `${classes.blacked}` : ``}`}
      >
        <div className={classes.navbarContainer}>
          <div className={classes.logo} onClick={() => navigate("/")}>
            <svg
              style={{ fill: color }}
              width="30px"
              height="30px"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5514 20.6057C18.3026 20.6057 14.8583 24.05 14.8583 28.2988H30.2445C30.2445 24.05 26.8001 20.6057 22.5514 20.6057Z"
                // fill={color}
              />
              <path
                d="M1.72437 15.982C1.72437 22.8567 7.29752 28.4299 14.1722 28.4299V3.53436C7.29752 3.53436 1.72437 9.10738 1.72437 15.982Z"
                // fill={color}
              />
            </svg>
            <p style={{ color: `${color}` }}>Robyn</p>
          </div>
          <div className={classes.links} style={{ color: `${color}` }}>
            <p
              className={location.pathname == `/` ? classes.active : ``}
              onClick={() => navigate("/")}
            >
              Home
            </p>
            <p
              className={
                location.pathname == `/playground` ? classes.active : ``
              }
              onClick={() => navigate("/playground")}
            >
              Playground
            </p>
            <p
              className={location.pathname == "/about" ? classes.active : ``}
              onClick={() => navigate("/about")}
            >
              About
            </p>
            <p
              onClick={() =>
                window.open(
                  `https://forms.gle/eZv3mGzGU9Qwojpn9`,
                  "_blank",
                  "noreferrer"
                )
              }
            >
              Join Us
            </p>
          </div>
          <div onClick={() => setNavOpen(true)} className={classes.hamburger}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 7H20"
                stroke={scroll ? `white` : color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M8 17H20"
                stroke={scroll ? `white` : color}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        className={`${classes.mobileNav} ${
          navOpen ? `${classes.navOpen}` : ``
        } `}
      >
        <svg
          onClick={() => setNavOpen(false)}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 6L18.7742 18.7742"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 18.7742L18.7742 5.99998"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p
          className={location.pathname == `/` ? classes.active : ``}
          onClick={() => {
            navigate("/");
            setNavOpen(false);
          }}
        >
          Home
        </p>
        <p
          className={location.pathname == "/playground" ? classes.active : ``}
          onClick={() => {
            navigate("/playground");
            setNavOpen(false);
          }}
        >
          Playground
        </p>
        <p
          className={location.pathname == "/about" ? classes.active : ``}
          onClick={() => {
            navigate("/about");
            setNavOpen(false);
          }}
        >
          About
        </p>
        <p
          onClick={() =>
            window.open(
              `https://forms.gle/eZv3mGzGU9Qwojpn9`,
              "_blank",
              "noreferrer"
            )
          }
        >
          Join Us
        </p>
      </div>
    </>
  );
};

export default Navbar;
